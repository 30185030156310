<template>
  <div id="app">
    <!-- <div class="layout">
      <left-menu></left-menu>
      <div class="right">
        <top-nav></top-nav> -->
    <router-view />
    <!-- </div> -->
    <!-- </div> -->
  </div>
</template>
<script>
import LeftMenu from "./components/LeftMenu.vue";
import TopNav from "./components/TopNav.vue";
export default {
  components: { LeftMenu, TopNav },
};
</script>

<style lang="less">
@import url(./assets/css/normalize.css);
#app {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: #f3f6fe;
}

.to-left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 32px;
}

html,
body,
#app {
  height: 100%;
}

.layout {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;

  .right {
    width: calc(100% - 70px);
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;

    .content {
      height: calc(100% - 50px);
      width: 100%;
      overflow-y: auto;
      padding: 0 20px 20px;
      box-sizing: border-box;
    }
  }
}

.card-box {
  padding: 32px;
  background-color: #fff;
  border-radius: 16px;

  .title {
    color: #2b2d49;
    font-size: 18px;
    font-weight: 500;
  }
}

// 滚动条整体宽度
::-webkit-scrollbar {
  width: 8px;
}
// 滚动条滑槽样式
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  border-radius: 8px;
}
// 滚动条样式
::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background: #ddd;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
}
::-webkit-scrollbar-thumb:hover {
  background: #ccc;
}
::-webkit-scrollbar-thumb:active {
  background: #999;
}
// 浏览器失焦的样式
::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(255, 0, 0, 0.4);
}

.avatar-uploader {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px !important;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
