import request from './request'

export default {
    toLogin: data => request.post('/login', data),
    getAccountList: data => request.get('/advertise/account/list', { params: data }),
    getMediaList: data => request.get('/advertise/channelType/list', { params: data }),
    deleteMedia: data => request.delete('/advertise/channelType/' + data),
    editMedia: data => request.put('/advertise/channelType', data),
    addMedia: data => request.post('/advertise/channelType', data),
    getCustomerList: data => request.get('/system/user/listClient', { params: data }),
    getDictList: data => request.get('/system/dict/type/list', { params: data }),
    getDictValueList: data => request.get(`/system/dict/data/type/${data}`),
    getUserList: data => request.get('/system/user/list', { params: data }),
    getDepartmentList: data => request.get('/system/user/deptTree', { params: data }),
    getDeptList: data => request.get('/system/dept/list', { params: data }),
    addDept: data => request.post('/system/dept', data),
    updateDept: data => request.put('/system/dept', data),
    deleteDept: data => request.delete(`/system/dept/${data}`),
    getRoleList: data => request.get('/system/role/list', { params: data }),
    getJobList: data => request.get('/system/post/list', { params: data }),
    addJob: data => request.post('/system/post', data),
    updateJob: data => request.put('/system/post', data),
    deleteJob: data => request.delete(`/system/post/${data}`),
    addUser: data => request.post('/system/user', data),
    deleteUser: data => request.delete(`/system/user/${data}`),
    editUser: data => request.put('/system/user/', data),
    getMenuList: data => request.get('/system/menu/list', { params: data }),
    getMenuTreeList: data => request.get('/system/menu/treeselect', { params: data }),
    addRole: data => request.post('/system/role', data),
    roleMenuTreeselect: roleId => request.get('/system/menu/roleMenuTreeselect/' + roleId),
    getRole: roleId => request.get('/system/role/' + roleId),
    updateRole: data => request.put('/system/role', data),
    deleteRole: data => request.delete(`/system/role/${data}`),
    deleteMenu: data => request.delete(`/system/menu/${data}`),
    addMenu: data => request.post('/system/menu', data),
    updateMenu: data => request.put('/system/menu', data),
    getRouters: data => request.get('/getRouters'),
    getUserInfo: data => request.get('/getInfo'),
}