import axios from 'axios'
import { Toast, Dialog } from 'vant';
import router from '../router/index'

const TIMEOUT = 30000
const BASE_URL = 'https://duodian-network.com/api'
// const BASE_URL = ''


const service = axios.create({
  baseURL: BASE_URL,
  timeout: TIMEOUT,
})

service.interceptors.request.use(config => {
  const token = localStorage.getItem('TOKEN')
  if (token) {
    config.headers.Authorization = token
  }
  return config
})

service.interceptors.response.use(response => {
  const data = response.data
  console.log(data)
  try {
    if (data.code === 200) {
      return data
    } else if (data.code === 401) {
      Toast({
        title: 'Tips',
        message: '登录已失效,请重新登录',
        confirmButtonText: "OK",
      })
      localStorage.setItem("TOKEN", '')
      router.push('/login')
    } else {
      const message = data.msg
      if (message) {
        Toast({
          title: "Tips",
          message: message,
          confirmButtonText: "OK",
        })
      } else {
        Toast({
          title: "Tips",
          message: 'System error!',
          confirmButtonText: "OK",
        })
      }

      return Promise.reject(data)
    }
  } catch (e) {
    Toast({
      title: "Tips",
      message: 'System error!',
      confirmButtonText: "OK",
    })
  }

}, error => {

  try {
    const message = error.response.data.msg
    if (message) {
      // Toast.fail(message)
    } else {
      Toast({
        title: "Tips",
        message: 'System error!',
        confirmButtonText: "OK",
      })
    }

    return Promise.reject(error)
  } catch (e) {
    Toast({
      title: "Tips",
      message: 'System error!',
      confirmButtonText: "OK",
    })
  }

})

export default service